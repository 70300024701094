(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  //Accordion
  var accordion_wrapper = $('.accordion-wrapper');
  accordion_wrapper.each(function () {
    $(this).find('.card .card-header button').click(function () {
      $(this).parents('.accordion-wrapper').find('.card').removeClass('active');
      $(this).parents('.card').addClass('active');
      var showCollapse = $(this).parents('.card').find('.collapse');

      if (showCollapse.hasClass('show')) {
        $(this).parents('.card').removeClass('active');
      }
    });
  });
}

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  //header
  var header = $('header');
  var navbarMenu = $('.navbar-nav');
  var searchBox = $('.search-box');
  header.find('.navbar-toggler').click(function () {
    $(this).toggleClass('active');
    header.toggleClass('active');
  });
  header.find('.search-item').click(function (e) {
    e.preventDefault();
    navbarMenu.hide();
    searchBox.fadeIn(700);
    searchBox.addClass('active');
    searchBox.find('input').focus();
  });
  searchBox.find('input').keyup(function () {
    searchBox.find('button').removeAttr('disabled');
    var this_val = $(this).val().length;

    if (this_val == 0) {
      searchBox.find('button').attr('disabled', 'disabled');
    }
  });
  var valid;
  searchBox.find('input').focusout(function () {
    var that = this;
    valid = this.value.length ? true : false;
    !valid && window.setTimeout(function () {
      searchBox.removeClass('active');
      searchBox.hide();
      navbarMenu.fadeIn(700);
      $(this).val('');
      searchBox.find('button').attr('disabled', true);
    }, 0);
  });
  searchBox.find('button').click(function (e) {
    if (!valid) {
      return false;
    }
  }); //footer

  var footer = $('footer');
  var footer_menu = footer.find('.col-menu');
  footer_menu.find('h4').click(function () {
    var _this = $(this);

    var _target = _this.parent('.col-menu').next();

    if (!_this.parents('.menu-wrap').hasClass('active')) {
      footer_menu.find('.menu-wrap').removeClass('active');

      _this.parents('.menu-wrap').addClass('active');
    } else {
      footer_menu.find('.menu-wrap').removeClass('active');
    }
  });
  var share_button = $('.share-button');
  share_button.find('.share-toggle').click(function (e) {
    e.preventDefault();
    share_button.find('ul').slideToggle();
  });
}

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  var cookie_policy = $('.cookie-policy');

  if (!Cookies.get("cookie_policy")) {
    cookie_policy.addClass('active');
  }

  cookie_policy.find('a.button-primary').click(function (e) {
    e.preventDefault();
    cookie_policy.removeClass('active');
    Cookies.set("cookie_policy", true, {
      expires: 999
    });
  });
}

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  var owlItemLength;
  var owl = $('.educations .owl-container');
  owlItemLength = owl.attr('data-item', owl.find('.education-card').length);
  var owlValue = Number(owl.attr('data-item'));

  if (owlValue >= 2) {
    owlValue = 2;
  } //education


  $('.educations .owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 700,
    margin: 30,
    //fluidSpeed:1000,
    loop: false,
    mouseDrag: true,
    navText: '',
    responsive: {
      0: {
        stagePadding: 30,
        margin: 15
      },
      576: {
        items: 1,
        slideBy: 1,
        stagePadding: 0
      },
      768: {
        items: owlValue,
        slideBy: owlValue,
        stagePadding: 0
      }
    }
  });
}

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  $.validator.addMethod("validate_email", function (value, element) {
    if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  });
  $.validator.addMethod("lettersonly", function (value, element) {
    return this.optional(element) || /^[a-zA-Z ıİşŞÜüğĞÇçöÖ]+$/i.test(value);
  });
  $("#contactForm,#applicationForm").validate({
    ignore: [],
    errorClass: "error",
    errorPlacement: function errorPlacement(error, element) {
      var elementName = element[0].className; //console.log(elementName);

      if (elementName == 'parent-error error') {
        $(element).parent(".form-item").addClass('error-validation');
        error.insertAfter($(element)); //error-msg add.
      } else {
        error.insertAfter($(element)); //error-msg add.
      }
    },
    rules: {
      educationDate: {
        required: true
      },
      nameSurname: {
        required: true,
        lettersonly: true
      },
      email: {
        required: true,
        validate_email: true
      },
      phone: {
        required: true,
        minlength: 15
      },
      message: {
        required: true
      },
      kvkk: {
        required: true
      },

      /*tei : {
      	required: true
      },*/
      hiddenRecaptcha: {
        required: function required() {
          if (grecaptcha.getResponse() == '') {
            return true;
          } else {
            return false;
          }
        }
      }
    },

    /*unhighlight: function (element, errorClass, validClass) {
    	console.log(element);
    	if ($(element).hasClass('hiddenRecaptcha')) {
    		//$(element).next().removeClass('error-validation');
    		console.log('sdsadasd');
    	}
    },*/
    submitHandler: function submitHandler(form) {
      // do other things for a valid form
      //console.log('form submit');
      //console.log('...');
      $(form).find('button').attr('disabled', 'disabled');
      formSend(form);
    }
    /*invalidHandler: function(form, validator) {
           $('html, body').animate({
               scrollTop: $(validator.errorList[0].element).offset().top-$('header').innerHeight() - 30
           }, 1000);
       }*/

  });
}

function formSend(form) {
  var appForm = $('.application-form .form-wrapper');
  var contactForm = $('.contact-form .form-wrapper');
  var formWrapper = $('.form-wrapper');
  var action = $(form).attr("action"),
      method = $(form).attr("method"),
      name = $(form).attr('name'),
      veri = $(form).serialize(),
      validate = $(form); // console.log(veri);

  $.ajax({
    type: method,
    url: action,
    data: veri,
    cache: false,
    success: function success(data) {
      //data.status = true;
      if (data.status == true) {
        //console.log('success');
        $(form).addClass('result-none');
        formWrapper.find('.form-result-status').addClass('success');
        appForm.find('.form-result').append('<i class="icon-check"></i><h4>Kaydınız başarıyla tamamlandı. Eğitim bilgileri ayrıca iletilecektir.</h4>');
        contactForm.find('.form-result').append('<i class="icon-check"></i><h4>İsteğini aldık. Size en kısa sürede bilgi vereceğiz.</h4>');
        setTimeout(function () {
          window.location.reload();
        }, 4000);
      } else {
        //console.log('error');
        $(form).addClass('result-none');
        formWrapper.find('.form-result-status').addClass('error');
        formWrapper.find('.form-result').append('<i class="icon-error"></i><h4>Hay aksi! Bir sorun oluştu. Lütfen daha sonra tekrar denermisiniz.</h4>');
        setTimeout(function () {
          window.location.reload();
        }, 4000);
      }
    }
  });
}

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  $('.js-phone-mask').mask('(500) 000 00 00');
}

},{}],7:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  //fix popup

  /*if (!Cookies.get("popup")) {
  	var popup = $("body").data("popup");
  
  	if (popup) {
  		$.fancybox.open({
  			src  : popup,
  			type : 'ajax',
  			opts : {
  				//afterShow : function( instance, current ) {
  					//console.info( 'done!' );
  				//},
  				afterClose : function( instance, current ) {
  					Cookies.set("popup", true, {expires: 999});
  				}
  			}
  		});
  	}
  }*/
  var popup = $("body").data("popup");
  var getUrl = window.location.pathname.replace('/', '');
  var url = '/ajax/get-popup?page=' + getUrl;

  if (popup != '') {
    if (!Cookies.get("popup")) {
      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: function success(response) {
          //console.log(response.popup.image);
          if (response.status) {
            var image, figure, title, subcontent, button;

            if (response.popup.onlyImage) {
              image = '<a href="' + response.popup.button_link + '" target="_blank"><img src="' + response.popup.image + '" alt=""></a>';
            } else {
              image = '';
            }

            if (response.popup.image && !response.popup.onlyImage) {
              figure = '<figure class="bg" style="background: url(/' + response.popup.image + ')"></figure>';
            } else {
              figure = '';
            }

            if (response.popup.title) {
              title = '<h2>' + response.popup.title + '</h2>';
            } else {
              title = '';
            }

            if (response.popup.subcontent) {
              subcontent = '<p">' + response.popup.subcontent + '</p>';
            } else {
              subcontent = '';
            }

            if (response.popup.button_link && response.popup.button_text) {
              button = '<a href="' + response.popup.button_link + '" class="button-primary">' + response.popup.button_text + '</a>';
            } else {
              button = '';
            }

            if (!response.popup.youtube_link) {
              $.fancybox.open({
                src: popup,
                type: 'ajax',
                opts: {
                  afterLoad: function afterLoad(instance, current) {
                    //console.info( 'done!' );
                    $(".dynamic-popup").append(image + figure + '<section>' + title + subcontent + button + '</section>');
                  },
                  afterClose: function afterClose(instance, current) {
                    Cookies.set("popup", true, {
                      expires: 999
                    });
                  }
                }
              });
            } else {
              $.fancybox.open({
                src: response.popup.youtube_link,
                //type : 'ajax',
                opts: {
                  afterClose: function afterClose(instance, current) {
                    Cookies.set("popup", true, {
                      expires: 999
                    });
                  }
                }
              });
            }
          }
        }
      });
    }
  }
}

},{}],8:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  $('.related-content .owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    transitionStyle: "fade",
    smartSpeed: 1000,
    slideBy: 2,
    //fluidSpeed:1000,
    loop: false,
    mouseDrag: true,
    margin: 30,
    navText: '',
    responsive: {
      0: {
        stagePadding: 30,
        margin: 15
      },
      576: {
        items: 1,
        slideBy: 1,
        stagePadding: 0
      },
      768: {
        items: 2,
        slideBy: 2,
        stagePadding: 0
      },
      991: {
        items: 3
      }
    }
  });
}

},{}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  if ($('.nav-scroll').length) {
    $('.nav-scroll').mCustomScrollbar({
      axis: "x"
    });
  }

  if ($('.special-select').length) {
    $('.special-select__list').mCustomScrollbar({
      axis: "y"
    });
  }
}

},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  var select = $('.special-select');
  select.each(function () {
    var thisSelect = $(this);
    var selectToggle = $(this).find('.select-toggle');
    var selectList = $(this).find('.special-select__list');
    var parentFormItem = $(this).parents('.form-item');
    selectToggle.click(function () {
      $(this).parents('.special-select').toggleClass('active');
    });
    selectList.find('a').click(function (e) {
      e.preventDefault();
      var this_text = $(this).html();
      parentFormItem.find('input').attr('value', this_text);
      parentFormItem.removeClass('error-validation');
      thisSelect.removeClass('active');
      thisSelect.find('.select-toggle').html(this_text);
      parentFormItem.find('label.error').hide();
    });
  });
}

},{}],11:[function(require,module,exports){
"use strict";

var _common = _interopRequireDefault(require("./app/common"));

var _education = _interopRequireDefault(require("./app/education"));

var _related = _interopRequireDefault(require("./app/related"));

var _scroll = _interopRequireDefault(require("./app/scroll"));

var _accordion = _interopRequireDefault(require("./app/accordion"));

var _popup = _interopRequireDefault(require("./app/popup"));

var _form = _interopRequireDefault(require("./app/form"));

var _mask = _interopRequireDefault(require("./app/mask"));

var _select = _interopRequireDefault(require("./app/select"));

var _cookie = _interopRequireDefault(require("./app/cookie"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

//import moreVideoJS from './app/moreVideo';
//import moreNewsJS from './app/moreNews';
$(document).ready(function () {
  (0, _common["default"])();
  (0, _education["default"])(); //moreVideoJS();
  //moreNewsJS();

  (0, _related["default"])();
  (0, _scroll["default"])();
  (0, _accordion["default"])();
  (0, _popup["default"])();
  (0, _form["default"])();
  (0, _mask["default"])();
  (0, _select["default"])();
  (0, _cookie["default"])();
});

},{"./app/accordion":1,"./app/common":2,"./app/cookie":3,"./app/education":4,"./app/form":5,"./app/mask":6,"./app/popup":7,"./app/related":8,"./app/scroll":9,"./app/select":10}]},{},[11]);
